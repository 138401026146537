import * as React from 'react'
import Layout from "../components/layout/layout";

const LiveSupport = () => {
    return (
        <Layout pageTitle="Live Support" pageKeywords="live support, belthosting support" pageDescription="Live Support">
            <div className="row">
                <div className="col-md-2"/>
                <div className="col-md-8">
                    <div className="page">
                        <div className="title">Live Support</div>
                        <div className="content">
                            <div className="text-center">
                                Write your nickname and hit Start... If nobody replies, <a
                                href="https://clients.belthosting.com/contact.php">contact us!</a>
                            </div>
                        </div>
                    </div>
                    <iframe title="Live Support" src="https://kiwiirc.com/nextclient/?settings=1f7845de517c76bda2dc942886239336" width="100%"
    height="680px" style={{border: 0, background: "#fff"}}/>
                </div>
                <div className="col-md-2"/>
            </div>
        </Layout>
    )
}
export default LiveSupport
